import { FC } from 'react';

import styled from 'styled-components';

/* possibly add min height and width as p */
/* ADD TRANSFORM, FLEX, AND ANIMATIONS AS PROPS */
export interface IBoxProps {
  //display
  show?: boolean;
  //placement
  center?: boolean;
  align?: string;
  alignContent?: string;
  alignSelf?: string;
  justify?: string;
  flexDirection?: string;
  //content placement
  flexWrap?: string;
  overflow?: string;
  //location
  zIndex?: string;
  position?: string;
  left?: string;
  top?: string;
  bottom?: string;
  right?: string;
  //spacing
  margin?: string;
  padding?: string;
  //sizing
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  minHeight?: string;
  //styling
  background?: string;
  borderWidth?: string;
  borderStyle?: string;
  borderColor?: string;
  borderRadius?: string;
  boxShadow?: string;
}

export const BaseBox = styled.div<IBoxProps>`
  display: ${(p) => (p.show ? 'flex' : 'none')};

  align-items: ${(p) => (p.center ? 'center' : p.align)};
  align-content: ${(p) => (p.center ? 'center' : p.alignContent)};
  align-self: ${(p) => p.alignSelf};
  justify-content: ${(p) => (p.center ? 'center' : p.justify)};
  flex-direction: ${(p) => p.flexDirection};

  flex-wrap: ${(p) => p.flexWrap};
  overflow: ${(p) => p.overflow};

  position: ${(p) => p.position};
  z-index: ${(p) => p.zIndex};
  left: ${(p) => p.left};
  top: ${(p) => p.top};
  bottom: ${(p) => p.bottom};
  right: ${(p) => p.right};

  margin: ${(p) => p.margin};
  padding: ${(p) => p.padding};

  width: ${(p) => p.width};
  height: ${(p) => p.height};
  max-width: ${(p) => p.maxWidth};
  max-height: ${(p) => p.maxHeight};
  min-width: ${(p) => p.minWidth};
  min-height: ${(p) => p.minHeight};
  background: ${({ theme, background }) =>
    background ? theme.color?.[background] || background : 'transparent'};
  border-width: ${(p) => p.borderWidth};
  border-style: ${(p) => p.borderStyle};
  border-color: ${(p) => p.borderColor};
  border-radius: ${(p) => p.borderRadius};
  box-shadow: ${(p) => p.boxShadow};
`;

export const Box: FC<IBoxProps> = ({
  children,
  center = false,
  show = true,
  align = 'stretch',
  alignContent = 'stretch',
  alignSelf = 'auto',
  justify = 'flex-start',
  flexDirection = 'row',
  flexWrap = 'nowrap',
  overflow = 'visible',
  zIndex = 'auto',
  position = 'static',
  left = 'auto',
  top = 'auto',
  bottom = 'auto',
  right = 'auto',
  margin = '0',
  padding = '0',
  width = 'auto',
  height = 'auto',
  maxWidth = 'none',
  maxHeight = 'none',
  minWidth = '0',
  minHeight = '0',
  background = 'initial',
  borderWidth = '0',
  borderStyle = 'none',
  borderColor = 'transparent',
  borderRadius = '0',
  boxShadow = 'none',
}) => (
  <BaseBox
    center={center}
    show={show}
    align={align}
    alignContent={alignContent}
    alignSelf={alignSelf}
    justify={justify}
    flexDirection={flexDirection}
    flexWrap={flexWrap}
    overflow={overflow}
    zIndex={zIndex}
    position={position}
    left={left}
    top={top}
    bottom={bottom}
    right={right}
    margin={margin}
    padding={padding}
    width={width}
    height={height}
    maxWidth={maxWidth}
    maxHeight={maxHeight}
    minWidth={minWidth}
    minHeight={minHeight}
    background={background}
    borderWidth={borderWidth}
    borderStyle={borderStyle}
    borderColor={borderColor}
    borderRadius={borderRadius}
    boxShadow={boxShadow}
  >
    {children}
  </BaseBox>
);
