import styled, { css } from 'styled-components';

import { VisuallyHiddenStyles } from '../visually-hidden';

const HiddenInput = styled.input`
  ${VisuallyHiddenStyles}
`;

const IconContainer = styled.div`
  position: absolute;
  z-index: 100;
  svg {
    padding: 2px;
  }
`;

const LabelWrapper = styled.label<{ $disabled?: boolean }>`
  display: flex;
  align-items: flex-start;
  margin: 8px 0;
  width: fit-content;
  cursor: ${(p) => (p.$disabled ? 'not-allowed' : 'pointer')};
  p {
    line-height: ${(p) => p.theme.token('height-checkbox-radio')};
  }
`;

const Check = styled.div<{ checked: boolean; $hasError?: boolean; $disabled?: boolean }>`
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  width: ${(p) => p.theme.token('width-checkbox-radio')};
  height: ${(p) => p.theme.token('height-checkbox-radio')};

  ${HiddenInput}:hover + ${LabelWrapper} & {
    &::after {
      ${(p) => `border-color: ${p.theme.token('border-color-hover')}`};
    }
  }

  ${HiddenInput}[disabled]:hover + ${LabelWrapper} & {
    &::after {
      ${(p) => `border-color: ${p.theme.token('border-color-input')}`};
    }
  }

  ${HiddenInput}:focus + ${LabelWrapper} & {
    &::after {
      box-shadow: ${(p) => p.theme.token('box-shadow-pattern')};
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: ${(p) => p.theme.token('border-radius-input')};
    transition: ${(p) => p.theme.token('transition-component')};

    ${({ $hasError, $disabled, checked, theme: { token } }) => {
      if (!$disabled && !$hasError && checked) {
        return css`
          background-color: ${token('background-checked')};
        `;
      }

      if ($hasError) {
        if (checked) {
          return css`
            background-color: ${token('background-error')};
          `;
        }
        return css`
          border-style: solid;
          border-width: ${token('border-width-input')};
          border-color: ${token('border-color-error')};
        `;
      }

      if ($disabled && checked) {
        return css`
          background-color: ${token('background-checkbox-disabled')};
        `;
      }

      return css`
        border-style: solid;
        border-width: ${token('border-width-input')};
        border-color: ${token('border-color-input')};
        background-color: ${token('background-input')};
      `;
    }}
  }
`;

export { Check, HiddenInput, IconContainer, LabelWrapper };
