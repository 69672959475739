import { Dialog, DialogBackdrop } from 'reakit/Dialog';
import styled from 'styled-components';

import { primitive } from '../../designTokens/primitives';

export const StyledDialogBackdrop = styled(DialogBackdrop)`
  background-color: ${primitive.$blackOpacity70};
  inset: 0px;
  position: absolute;
  z-index: 500;
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  &[data-enter] {
    opacity: 1;
  }
`;

export const StyledDialog = styled(Dialog)`
  background: ${(p) => p.theme.token('background-pattern')};
  border-top-left-radius: ${(p) => p.theme.token('border-radius-pattern')};
  border-top-right-radius: ${(p) => p.theme.token('border-radius-pattern')};
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 100px);
  transition: transform 250ms ease-in-out;
  &:focus {
    outline: none;
  }
  &[data-enter] {
    transform: translateY(0);
  }
`;

export const BottomDrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 ${primitive.$spacing10};
  color: ${(p) => p.theme.token('text-button-tertiary-default')};
  font: ${(p) => p.theme.token('text-style-card-header')};
  border-bottom: 1px solid ${primitive.$blackOpacity10};
`;

export const BottomDrawerBody = styled.div`
  flex: 1;
  overflow: auto;
`;

export const BottomDrawerCloseButton = styled.button`
  background: ${(p) => p.theme.token('background-pattern')};
  position: absolute;
  border-radius: 50%;
  left: ${primitive.$spacing1};
  top: ${primitive.$spacing1};
  padding: ${primitive.$spacing1};
  margin: ${primitive.$spacing1};
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
