import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $disabled?: boolean }>`
  width: fit-content;
  display: flex;
  align-items: center;
  ${(p) => p.$disabled && 'cursor: not-allowed;'}
`;

export const Input = styled.input<{ $isHidden?: boolean }>`
  transition: 0.3s;
  height: 48px;
  width: 48px;
  border-radius: ${(p) => p.theme.token('border-radius-input')};
  background-color: ${(p) => p.theme.token('background-input')};
  margin: 0 8px;
  text-align: center;
  border: ${(p) => p.theme.token('border-width-input')} solid;
  border-color: transparent;
  font: ${(p) => p.theme.token('text-style-form-input')};
  color: ${(p) => p.theme.token('text-default')};

  ${(p) => p.$isHidden && 'display: none;'}

  // Remove the native steppers
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
    box-shadow: ${(p) => p.theme.token('box-shadow-pattern')};
    border-color: ${(p) => p.theme.token('border-color-input')};
  }

  &:hover {
    ${(p) => !p.disabled && `border-color: ${p.theme.token('border-color-hover')}`};
  }

  &:disabled {
    color: ${(p) => p.theme.token('text-disabled')};
    border-color: transparent;
    cursor: not-allowed;
  }
`;

export const DisplayedValue = styled.div<{ $width?: string; $disabled?: boolean }>`
  height: 48px;
  min-width: 48px;
  background: ${(p) => p.theme.token('background-input')};
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) => p.$disabled && `color: ${p.theme.token('text-disabled')};`}

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}
`;
