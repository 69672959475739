import { LabelHeader, LabelText, Wrapper } from './label.styled';
import { ILabelProps } from './types';

const Label = ({
  htmlFor,
  id,
  label,
  labelHeader,
  disabled,
  'data-testid': testId,
}: ILabelProps) => {
  if (!label) {
    return null;
  }

  return (
    <Wrapper id={id} $disabled={disabled} data-testid={testId ?? 'label'}>
      {labelHeader && (
        <LabelHeader data-testid={'label-header'} $disabled={disabled}>
          {labelHeader}
        </LabelHeader>
      )}
      <LabelText htmlFor={htmlFor} data-testid={'label-text'} $disabled={disabled}>
        {label}
      </LabelText>
    </Wrapper>
  );
};

export default Label;
