import { FC } from 'react';

import { Icon } from '../icon';

import {
  BottomDrawerBody,
  BottomDrawerCloseButton,
  BottomDrawerHeader,
  StyledDialog,
  StyledDialogBackdrop,
} from './bottom-drawer.styled';
import { IBottomDrawerProps } from './types';

/**
 * Show options in a Drawer, which appears from bottom to top. It can be closed as well through backdrop click or close button click
 */
const BottomDrawer: FC<IBottomDrawerProps> = ({
  header,
  dialogState,
  children,
  hideOnClickOutside = true,
  'aria-label': ariaLabel,
  'data-testid': dataTestId,
}) => {
  return (
    <StyledDialogBackdrop data-testid={dataTestId} {...dialogState}>
      <StyledDialog
        data-testid="bottom-drawer-body"
        {...dialogState}
        hideOnClickOutside={hideOnClickOutside}
        aria-label={ariaLabel}
      >
        <BottomDrawerCloseButton
          data-testid="bottom-drawer-close-btn"
          aria-label="Close"
          onClick={dialogState.hide}
        >
          <Icon icon="close" color="icon-default" height="30px" width="30px" />
        </BottomDrawerCloseButton>
        <BottomDrawerHeader>{header}</BottomDrawerHeader>
        <BottomDrawerBody>{children}</BottomDrawerBody>
      </StyledDialog>
    </StyledDialogBackdrop>
  );
};

export default BottomDrawer;
