import styled, { css } from 'styled-components';

import { VisuallyHiddenStyles } from '../visually-hidden';

import { IRadioButtonProps } from './types';

const HiddenInput = styled.input`
  ${VisuallyHiddenStyles}
`;

const linkStyles = css`
  a {
    color: ${(p) => p.theme.token('text-link-default')};
    text-decoration: underline;
    &:hover {
      color: ${(p) => p.theme.token('text-link-hover')};
    }
  }
`;

const LabelText = styled.span<{ disabled?: boolean }>`
  font: ${(p) => p.theme.token('text-style-form-checkbox')};
  color: ${(p) => p.theme.token(p.disabled ? 'text-disabled' : 'text-default')};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  ${linkStyles}
`;

const LabelHeader = styled.p<{ disabled?: boolean }>`
  font: ${(p) => p.theme.token('text-style-form-checkbox-header')};
  color: ${(p) => p.theme.token(p.disabled ? 'text-disabled' : 'text-default')};
  margin: 0 0 4px 0;
  ${linkStyles}
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 8px;
`;

const Container = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: flex-start;
  width: fit-content;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  margin-bottom: 8px;
`;

const IndicatorContainer = styled.span<{ disabled?: boolean }>`
  min-width: ${(p) => p.theme.token('width-checkbox-radio')};
  min-height: ${(p) => p.theme.token('height-checkbox-radio')};
  position: relative;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    inset: 1px 0px -1px;
    border-radius: 50%;
  }
`;

const Indicator = styled.span<IRadioButtonProps>`
  border-radius: 50%;
  width: ${(p) => p.theme.token('width-checkbox-radio')};
  height: ${(p) => p.theme.token('height-checkbox-radio')};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${(p) => p.theme.token('transition-component')};

  ${HiddenInput}:focus + ${IndicatorContainer} & {
    box-shadow: ${(p) => p.theme.token('box-shadow-pattern')};
  }

  ${HiddenInput}:hover + ${IndicatorContainer} & {
    border-color: ${(p) => p.theme.token('border-color-hover')};
  }

  ${HiddenInput}[disabled]:hover + ${IndicatorContainer} & {
    border-color: ${(p) => p.theme.token('border-color-input')};
  }

  ${({ hasError, disabled, checked, theme: { token } }) => {
    if (!disabled && !hasError && checked) {
      return css`
        background-color: ${token('background-checked')};
      `;
    }
    if (hasError) {
      if (checked) {
        return css`
          background-color: ${token('background-error')};
        `;
      }
      return css`
        border-style: solid;
        border-width: ${token('border-width-input')};
        border-color: ${token('border-color-error')};
      `;
    }
    if (disabled && checked) {
      return css`
        background-color: ${token('background-checkbox-disabled')};
      `;
    }
    return css`
      border-style: solid;
      border-width: ${token('border-width-input')};
      border-color: ${token('border-color-input')};
      background-color: ${token('background-input')};
    `;
  }}

  &::after {
    ${(p) => p.checked && `background-color: ${p.theme.token('background-input')}`};
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
`;

export {
  HiddenInput,
  Indicator,
  IndicatorContainer,
  LabelWrapper,
  LabelText,
  LabelHeader,
  Container,
};
