import { FC } from 'react';

import { useIdCounter } from '../../hooks/use-id-counter';

import {
  Container,
  HiddenInput,
  Indicator,
  IndicatorContainer,
  LabelHeader,
  LabelText,
  LabelWrapper,
} from './radio-button.styled';
import { IRadioButtonProps } from './types';

const RadioButton: FC<IRadioButtonProps> = ({
  checked,
  id: radioId,
  name,
  disabled,
  onChange,
  value,
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
  label,
  labelHeader = '',
  hasError = false,
}) => {
  const defaultRadioId = useIdCounter('radio-button');
  const labelID = useIdCounter('label');
  const uniqueId = radioId ?? defaultRadioId;

  return (
    <Container disabled={disabled}>
      <HiddenInput
        type="radio"
        checked={checked}
        disabled={disabled}
        name={name}
        id={uniqueId}
        value={value}
        data-testid={dataTestId}
        aria-label={ariaLabel}
        onChange={onChange}
      />
      <IndicatorContainer
        disabled={disabled}
        {...(dataTestId && { 'data-testid': `${dataTestId}_label_wrapper` })}
      >
        <Indicator
          label={label}
          checked={checked}
          disabled={disabled}
          hasError={hasError}
          {...(dataTestId && { 'data-testid': `${dataTestId}_indicator` })}
        />
      </IndicatorContainer>
      <LabelWrapper>
        {labelHeader && <LabelHeader>{labelHeader}</LabelHeader>}
        <LabelText id={labelID} disabled={disabled}>
          {label}
        </LabelText>
      </LabelWrapper>
    </Container>
  );
};

export default RadioButton;
