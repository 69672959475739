import { SVGIconComponent } from '../types';

export const cluckNorrisLoveThatChicken: SVGIconComponent = ({ title = '', ...props }) => (
  <svg version="1.1" viewBox="0 0 150 150" {...props}>
    <title>{title}</title>
    <g>
      <path d="M0.92,95.63l6.75,8.14c0,0,3.61-5.3,8.05-1.74c4.45,3.56,1.34,10.86,0.93,15.06c-0.52,5.35,0.8,9.68,2.95,13.12 c2.74,4.37,9.1,8.01,15.93,9.3c0.52,0.1,1.02,0.17,1.52,0.23l0.71,7.53h5.15l0.69-7.36c11.81-0.95,28.51-12.5,21.08-31.96 c-3.42-8.95-10.67-22.31-10.67-22.31l0.08-0.02c2.87-0.73,3.42-4.45,0.07-6.45l2.4-2.22l-3.5-1.44l1.31-4.79l-7.52,2.98 c0.01-0.01,0.02-0.02,0.02-0.02s-1.91-3.98-4.8-4.01c-2.44-0.03-3.6,1.74-3.6,1.74s-0.65-1.82-2.32-2.31 c-1.67-0.5-4.4,0.48-4.74,3.51c-0.39,3.4,2.36,5.15,3.26,5.44c1.07,0.34,2.56,1.04,5.66-0.28c-0.01,3.12-0.02,10.35-0.02,13.99 c-0.01,4.71,0.42,12.35-1.61,16.5c-2.02,4.14-4.33,5.87-7.38,5.51c-3.41-0.4-4.58-3.59-3.39-9.52c1.19-5.93,2.19-11.82-2.78-16.22 C20.18,83.66,11.24,83.18,0.92,95.63z M47.51,76.61c0.77-0.39,1.75-0.01,2.19,0.85c0.44,0.86,0.18,1.88-0.59,2.27 c-0.77,0.39-1.75,0.01-2.19-0.85C46.47,78.02,46.74,77,47.51,76.61z" />
      <g>
        <polygon points="47.07,145.43 47.69,145.43 47.69,147.28 47.97,147.28 47.97,145.43 48.59,145.43 48.59,145.17 47.07,145.17 49.91,146.68 49.25,145.17 48.89,145.17 48.89,147.28 49.16,147.28 49.16,145.58 49.78,146.99 50.05,146.99 50.67,145.58 50.67,147.28 50.94,147.28 50.94,145.17 50.57,145.17 	" />
      </g>
      <g id="Love_that_Chicken_2_">
        <path
          d="M76.57,43.17c0.57,0.36,0.4,1.37,0.55,1.95c0.24,0.88,0.61,1.74,0.94,2.59c0.23,0.6,0.51,0.98,0.97,1.43 c0.65,0.64,1.03,1.44,1.37,2.28c1.22,2.94,1.92,6.08,3,9.07c0.41,1.12,0.87,1.96,2.19,1.65c0.46-0.11,1.59-0.46,1.86-0.89 c0.18-0.28,0-0.71-0.12-1.06c-1.15-3.28-2.8-6.45-4.16-9.65c-0.84-1.97-1.68-3.94-2.45-5.93c-0.15-0.38-0.48-0.94-0.49-1.36 c-0.02-0.65,0.77-1.14,1.32-1.48c0.9-0.57,3.05,0.01,3.58-0.94c0.34-0.6-0.12-1.38-0.69-1.59c-0.6-0.22-1.36,0.01-1.95,0.18 c-3.16,0.9-6.36,1.57-9.59,2.23c-2.01,0.41-4.35,0.63-6.21,1.54c-0.62,0.3-1.37,0.79-1.69,1.42c-0.28,0.55-0.07,0.61,0.63,0.82 c0.94,0.28,1.64-0.22,2.51-0.49c1.03-0.32,2.09-0.28,3.15-0.41c0.85-0.11,1.63-0.38,2.42-0.71C74.47,43.49,75.76,42.66,76.57,43.17 z
        M99.56,51.12c0.06-1.75-1.05-3.08-1.53-4.66c-0.33-1.11-0.81-2.18-1.28-3.24c-0.91-2.07-2-4.17-2.49-6.39 c-0.19-0.88-0.82-2.85-2.18-1.92c-1.05,0.71,0.13,2.62,0.48,3.47c1.08,2.61,2.43,5.18,3.33,7.86c0.54,1.61,0.76,3.51,1.57,5.02 C97.84,51.99,99.52,52.42,99.56,51.12z
        M95.28,47.87c-0.69-0.06-1.42-0.01-2.11,0.08c-0.51,0.06-1.52,0.55-1.98,0.44c-0.61-0.15-1.03-1.57-1.29-2.15 c-0.3-0.67-0.65-1.34-0.8-2.06c-0.1-0.47-0.05-1.08-0.44-1.43c-0.66-0.59-2.48-0.25-2.62,0.73c-0.11,0.82,0.81,2.09,1.17,2.79 c0.55,1.07,1.11,2.14,1.68,3.2c0.64,1.19,1.22,2.32,1.57,3.63c0.29,1.09,0.67,2.1,1.19,3.08c0.18,0.33,0.6,0.41,0.89,0.18 c0.12-0.09,0.23-0.19,0.3-0.31c0.28-0.45,0.31-1.55,0.33-2.1c0.02-0.71-0.24-1.28-0.61-1.87c-0.31-0.49-0.9-0.98-0.51-1.49 c0.32-0.4,1.19-0.4,1.63-0.51c0.5-0.13,1.04-0.31,1.52-0.5C96.13,49.22,96.74,48.01,95.28,47.87z
        M69.71,33.44c0.07-0.88,1.99-0.27,2.6-0.24c1.18,0.05,2.46-0.03,3.52-0.59c1.04-0.54,1.74-1.31,2.31-2.31 c0.24-0.4,0.46-1.51,0.46-1.51c-0.03-0.2-0.14-0.44-0.21-0.63c-0.11-0.31-0.22-0.62-0.31-0.94c-0.2-0.68-0.37-1.36-0.52-2.05 c-0.3-1.38-0.51-2.77-0.61-4.18c-0.11-1.43-0.15-2.91-0.08-4.34c0.07-1.36,0.31-2.72,0.85-3.98c0.09-0.21,0.19-0.42,0.3-0.63 c0.78-1.5,2.47-3.27,4.04-1.57c1.93,2.09,1.72,5.63,1.51,8.2c-0.19,2.33-0.97,4.61-1.78,6.82c-0.58,1.58-1,3.02-0.94,3.25 c0.09,0.36,2.25,2.77,4.81,2.24c2.01-0.41,3.32-1.83,3.74-3.79c0.27-1.28,0.22-2.63,0.3-3.93c0.08-1.28,0.54-2.32,1.32-3.34 c0.92-1.19,2.17-2.14,3.71-2.28c1.49-0.14,2.94,0.54,3.65,1.87c0.7,1.32,0.78,2.92,0.75,4.39c-0.01,0.36-0.03,0.72-0.05,1.08 c-0.12,1.81-0.99,3.31-2.42,4.4c-1.71,1.31-3.88,1.05-5.62-0.16c-0.26,2.65-2.02,4.7-4.69,5.12c-2.69,0.42-4.47-1.41-6.29-3.08 c-1.13,1.44-2.23,3.33-4,4.05c-0.64,0.26-1.32,0.35-2.01,0.41c-0.09,0.01-0.23,0.01-0.39,0c-0.59-0.01-1.17-0.13-1.72-0.35 C70.93,35,69.63,34.37,69.71,33.44z M92.43,26.89c0.59,0.32,1.47,0.31,2.08,0.05c1.52-0.65,2-3.18,1.66-4.61 c-0.13-0.54-0.36-1.06-0.48-1.6c-0.1-0.44-0.07-1-0.32-1.39c-0.64-0.98-1.78-0.47-2.36,0.21c-1.29,1.53-2.05,3.04-1.68,5.07 C91.49,25.49,91.57,26.43,92.43,26.89z M78.92,23.65c0.19,0.69,0.45,1.36,0.78,2.01c0.01,0.03,0.05,0.04,0.07,0.02 c1.04-1.08,1.17-3.25,1.43-4.6c0.41-2.12,1.04-4.38,0.93-6.55c-0.03-0.69-0.23-1.7-1.19-1.52c-0.98,0.18-1.71,1.77-2.01,2.6 c-0.41,1.15-0.51,2.4-0.53,3.62C78.38,20.71,78.51,22.21,78.92,23.65z
        M111.96,41.76c0,0.04,0,0.08,0,0.13c-0.01,0.95-0.18,2.13-0.86,2.85c-1.29,1.37-3.67,1.2-4.73-0.34 c0.08,0.12-0.83,0.95-0.93,1.03c-1.14,0.98-2.87,1.18-4.19,0.42c-2.16-1.26-1.95-4.65-0.21-6.15c0.52-0.45,1.17-0.75,1.85-0.88 c0.74-0.13,1.58-0.18,2.32-0.13c0.01,0-0.16-0.7-0.19-0.77c-0.11-0.26-0.28-0.5-0.52-0.66c-0.48-0.33-1.06-0.54-1.64-0.56 c-0.64-0.02-1.21,0.18-1.76,0.49c-0.23,0.13-0.57,0.31-0.84,0.21c-0.32-0.11-0.27-0.57-0.18-0.82c0.08-0.2,0.21-0.38,0.35-0.55 c0.79-0.92,1.98-1.45,3.18-1.47c0.63-0.01,1.28,0.13,1.81,0.49c1.77,1.19,2,2.95,2.4,4.93c0.15,0.77,0.57,4.49,2.13,3.46 c0.55-0.36,0.82-1.18,0.87-1.79c0.03-0.39-0.02-0.77,0.06-1.16c0.1-0.49,0.6-0.36,0.79,0C111.87,40.89,111.95,41.32,111.96,41.76z M105.61,40.89c-0.02-0.09-0.12-0.54-0.12-0.54c-0.52-0.15-1.25-0.01-1.75,0.18c-0.57,0.22-1.06,0.64-1.36,1.18 c-0.41,0.76-0.43,1.82,0.06,2.55c0.14,0.2,0.33,0.38,0.57,0.44c0.36,0.08,0.71-0.12,1-0.31c0.62-0.42,1.23-0.91,1.59-1.59 C105.9,42.23,105.73,41.5,105.61,40.89z
        M96.67,13.27c0.32,0.78,2.08,2.6,3.19,4.06c1.53,2.02,3.15,3.98,4.81,5.9c1.06,1.23,2.16,2.94,3.48,3.9 c0.53,0.38,1.01,0.3,1.36-0.26c0.5-0.81,0.08-1.59-0.23-2.36c-0.69-1.76-0.48-3.93-0.66-5.79c-0.27-2.68-0.05-5.13,0.21-7.91 c0.03-0.37,0.06-0.73,0.08-1.1c0.03-0.94,0.2-1.88,0.19-2.83c0-0.28-0.04-0.55-0.08-0.83c-0.04-0.2-0.08-0.4-0.13-0.6 c-0.1-0.37-0.23-0.76-0.5-1.05c-0.78-0.83-1.05,0.61-1.13,1.25c-0.15,1.26-0.32,2.5-0.39,3.76c-0.17,3.03-0.12,6.09-0.45,9.11 c-0.01,0.11-0.03,0.22-0.04,0.33c-0.02,0.19-0.04,0.38-0.06,0.57c-0.02,0.23-0.05,0.45-0.07,0.68c-0.02,0.22-0.04,0.44-0.05,0.66 c-0.01,0.16-0.02,0.33-0.01,0.5c0,0.07,0.01,0.13,0.01,0.2c0,0.03,0.01,0.06-0.03,0.04c-0.03-0.01-0.06-0.03-0.09-0.05 c-0.11-0.07-0.21-0.15-0.3-0.24c-0.16-0.15-0.3-0.32-0.44-0.49c-0.15-0.18-0.29-0.37-0.42-0.56c-0.11-0.16-0.22-0.32-0.33-0.47 c-0.04-0.06-0.08-0.12-0.13-0.18c-1.21-1.53-2.49-2.96-3.65-4.53c-0.7-0.96-1.46-2.46-2.44-3.16c-0.39-0.28-1.01-0.35-1.5-0.14 C96.39,11.9,96.22,12.18,96.67,13.27z
        M113.32,29.34c-0.2,0.36-0.29,0.81-0.27,1.26c0.05,1.21,0.59,2.37,0.84,3.54c-0.01-0.03-1.13,0.47-1.22,0.51 c-0.31,0.13-0.64,0.24-0.95,0.38c-0.44,0.2-1.13,0.38-1.25,0.93c-0.38,1.61,3.64,0.59,4.37,0.42c0.09,0.65,0.34,1.26,0.49,1.89 c0.19,0.8,0.3,1.61,0.52,2.4c0.43,1.55,1.46,2.81,2.98,3.39c1.29,0.49,3,0.54,4.1-0.4c0.7-0.6,1.1-1.61,0.48-2.41 c-0.15-0.19-0.36-0.35-0.61-0.39c-0.18-0.03-0.37,0-0.55,0.06c-0.59,0.23-0.73,0.93-1.3,1.15c-0.33,0.13-0.71,0.04-1.03-0.1 c-1.24-0.55-1.67-1.76-1.94-3c-0.27-1.23-0.64-2.42-0.72-3.69c1.65-0.75,3.42-1.28,5.19-1.68c0.39-0.09,0.8-0.17,1.12-0.42 c0.71-0.54,0.45-1.8-0.48-1.89c-0.72-0.07-1.67,0.34-2.37,0.51c-1.37,0.35-2.76,0.68-4.1,1.14c-0.12-0.56-0.29-1.11-0.4-1.67 c-0.09-0.47-0.13-0.95-0.25-1.41c-0.19-0.75-0.35-1.24-1.2-1.32C114.09,28.5,113.6,28.84,113.32,29.34z
        M112.79,19.09c-0.21-2.1,0.33-4.27,1.86-5.78c2.34-2.3,6.33-2.42,8.54,0.12c1.23,1.41,1.76,3.09,0.07,4.33 c-1,0.73-2.19,1.13-3.32,1.64c-1.12,0.5-2.26,1.04-3.47,1.36c0.49,1.23,1.8,2.42,2.82,3.2c0.96,0.74,2.13,1.26,3.34,1.22 c0.83-0.02,1.7-0.35,2.17-1.04c0.67-0.97,0.38-2.32,0.87-3.38c0.1-0.22,0.24-0.43,0.45-0.55c0.32-0.19,0.75-0.1,1.05,0.12 c0.3,0.23,0.49,0.57,0.62,0.92c0.48,1.24,0.37,2.7-0.29,3.85c-2.1,3.65-7.48,2.57-10.43,0.81 C114.53,24.41,113.08,21.99,112.79,19.09z M120.05,17.03c0.27-0.14,0.53-0.28,0.79-0.44c0.46-0.27,0.9-0.7,0.71-1.28 c-0.09-0.29-0.31-0.52-0.54-0.71c-0.7-0.59-2.24-1.19-3.13-0.74c-1.05,0.53-1.95,1.42-2.14,2.62c-0.11,0.68,0.01,1.57,0.08,2.24 c0,0,2.25-0.81,2.49-0.9C118.91,17.58,119.49,17.32,120.05,17.03z
        M99.89,69.23c-0.12-0.63-0.16-1.29-0.3-1.92c-0.13-0.59-0.48-0.96-0.82-1.46c-0.32-0.46-0.64-0.93-0.82-1.47 c-0.21-0.65-0.16-1.32-0.37-1.97c-0.36-1.09-1.22-0.97-1.63-0.83c-0.44,0.15-0.76,0.1-1.12,0.51c-0.25,0.28-0.15,0.54-0.14,0.68 c0.06,0.74,0.38,1.27,0.61,1.95c0.18,0.54,0.16,1.12,0.34,1.65c0.19,0.57,0.58,0.81,0.92,1.26c0.41,0.54,0.28,0.98,0.32,1.61 c0.03,0.6,0.25,1.11,0.52,1.63c0.26,0.52,0.43,1.08,0.74,1.57c0.5,0.78,0.99,0.41,1.65-0.06c0.58-0.41,0.81-0.71,0.78-1.42 C100.53,70.25,100.01,69.88,99.89,69.23z
        M124.84,61.82c-1.48-1.21-3.11-2.23-4.73-3.25c-0.81-0.51-1.62-1.01-2.42-1.55c-0.39-0.26-1.37-0.59-1.33-1.18 c0.01-0.17,0.09-0.33,0.16-0.48c0.98-2,1.85-4.05,2.82-6.06c0.15-0.32,0.31-0.65,0.32-1.01s-0.17-0.74-0.51-0.85 c-0.4-0.13-0.81,0.15-1.08,0.46c-0.49,0.56-0.78,1.25-1.14,1.9c-0.96,1.74-2.43,3.24-3.41,4.97c-0.29-0.7-0.49-1.14-0.64-1.74 s-0.41-1.24-0.97-1.5c-0.14-0.06-0.31-0.1-0.43-0.01c-0.13,0.09-0.16,0.28-0.16,0.44c0.01,0.49,0.16,0.96,0.33,1.43 c0.8,2.25,1.83,4.42,2.62,6.68c0.5,1.42,0.91,2.86,1.41,4.28c0.18,0.51,0.4,1.04,0.84,1.36c0.44,0.32,1.17,0.29,1.42-0.18 c0.21-0.38,0.03-0.85-0.14-1.25c-0.81-1.86-1.59-3.83-2.4-5.69c1.29,0.27,2.56,0.8,3.65,1.49c0.85,0.54,1.65,1.17,2.51,1.7 c1.05,0.65,2.18,1.1,3.27,1.69C126.52,64.37,125.59,62.43,124.84,61.82z
        M68.93,53.68c-1.67-0.36-3.36-0.01-4.79,1.65c-1.46,1.69-1.94,4.01-2.07,6.24c-0.19,3.31,0.34,6.71,1.74,9.73 c1.09,2.36,3.62,6.49,6.65,6.37c0.66-0.03,1.3-0.28,1.89-0.58c3.24-1.63,6.48-5.16,4.65-8.96c-0.48-1-1.83-1.81-2.71-0.83 c-0.68,0.76-0.93,1.98-1.22,2.92c-0.44,1.43-0.95,4.41-2.73,4.85c-1.13,0.28-2.07-0.85-2.65-1.86c-2.39-4.18-4.33-9.55-2.59-14.4 c0.6-1.68,1.94-2.46,3.74-2.1c1.89,0.39,2.27,3.04,4.19,3.57c0.92,0.26,1.85-0.58,2.13-1.42c0.35-1.02-0.48-1.6-1.16-2.23 C72.59,55.32,70.77,54.07,68.93,53.68z
        M109.27,59.4c-1.22,0.11-1.61,2.36-2.08,3.21c-0.44,0.8-1.43,1.51-2.36,1.12c-0.42-0.18-0.76-0.5-1.08-0.84 c-0.8-0.86-1.51-1.84-1.91-2.95c-0.49-1.33-0.59-3.23,0.34-4.41c0.32-0.4,0.81-0.65,1.31-0.75c0.51-0.1,1.12-0.12,1.61,0.05 c0.51,0.19,0.94,0.57,1.39,0.86c0.78,0.5,1.99,0.86,2.61-0.1c0.61-0.94-0.82-1.38-1.53-1.71c-2.79-1.31-6.54-0.98-7.63,2.38 c-0.44,1.35-0.29,2.84,0.19,4.18c0.72,1.97,2.22,3.69,4.18,4.44c0.82,0.32,1.75,0.45,2.58,0.09c0.91-0.39,1.75-0.94,2.57-1.49 c0.7-0.46,1.49-1.15,1.61-2.04C111.23,60.34,110.41,59.3,109.27,59.4z
        M133.36,51.08c-1.37,0.22-2.48,1.3-3.56,2.09c-0.3,0.22-0.57,0.48-0.86,0.71c-0.38,0.31-0.8,0.57-1.2,0.85 c-0.37-0.4-0.75-0.81-1.12-1.21c0.07-0.06,0.13-0.12,0.2-0.18c0.67-0.61,1.3-1.24,2.01-1.8c0.6-0.47,1.23-0.91,1.86-1.33 c0.25-0.16,0.24-0.52-0.01-0.69c-0.32-0.21-0.63-0.3-1.12-0.11c-0.64,0.25-1.24,0.88-1.78,1.29c-0.36,0.27-0.69,0.59-1.05,0.86 c-0.14,0.11-0.27,0.24-0.41,0.34c-0.18,0.13-0.43,0.26-0.62,0.36c0,0-0.46-0.56-0.5-0.63c-0.07-0.09-0.76-1.14-0.68-1.2 c0.87-0.66,1.72-1.36,2.54-2.08c0.57-0.5,1.59-0.96,2.16-1.61c0.35-0.4,0.04-1.02-0.48-0.98c-2.25,0.2-3.7,2.08-5.61,3.24 c-1.14,0.7-1.4,0.93-0.53,2.11c0.76,1.03,1.45,2.03,2.15,3.09c0.57,0.86,1.38,1.93,2.24,2.52c0.88,0.61,1.6-0.25,2.3-0.79 c1.01-0.78,2.05-1.56,2.94-2.48c0.43-0.44,0.89-0.93,1.24-1.44C133.61,51.83,134.08,50.96,133.36,51.08z
        M91.74,68.97c-0.08-0.92-0.6-1.99-1.03-2.78c-0.21-0.39-0.44-0.88-0.95-0.9c-0.77-0.03-0.86,0.77-0.8,1.33 c0.12,1.15,0.8,2.42-0.68,3.04c-0.54,0.23-1.09,0.46-1.66,0.61c-0.34,0.09-0.69,0.16-1.03,0.22c-0.28,0.05-0.62,0.19-0.91,0.18 c-0.15,0-0.21-0.11-0.27-0.23c-0.5-0.91-1-1.82-1.52-2.71c-0.25-0.42-0.57-1.28-1-1.55c-0.98-0.62-1.2,0.87-1.04,1.51 c0.28,1.13,0.68,2.33,1.05,3.46c0.74,2.27,0.85,4.57,1.42,6.88c0.15,0.59,0.71,1.6,1.38,0.76c0.44-0.55,0.18-1.44,0.02-2.04 c-0.17-0.61-0.37-1.24-0.52-1.87c-0.11-0.49-0.35-1.23-0.18-1.73c0.13-0.38,0.62-0.61,0.96-0.78c1.31-0.64,2.68-0.95,4.13-0.91 c0.26,0.01,0.54,0.04,0.74,0.21c0.32,0.25,0.49,0.77,0.66,1.12c0.47,0.95,0.74,2.11,1.5,2.88c0.81,0.83,2.03,0.61,1.98-0.7 c-0.03-0.79-0.64-1.56-1-2.24C92.37,71.56,91.86,70.31,91.74,68.97z
        M146.16,45.26c-0.19-0.32-0.37-0.64-0.63-0.9c-0.29-0.29-0.79-0.45-1.04-0.75c-0.61-0.75-0.78-2.25-1.17-3.15 c-0.6-1.4-1.18-2.86-1.88-4.2c-0.64-1.22-1.41-2.37-2.16-3.53c-0.64-0.98-1.06-1.74-1.45-2.82c-0.56-1.55-2.24-3.22-3.96-3.38 c-2.06-0.19-0.97,1.89-0.4,2.77c1.36,2.11,2.84,4.16,4.23,6.25c0.67,1.01,1.37,2.02,1.98,3.07c0.33,0.58,0.61,1.19,0.96,1.76 c0.29,0.48,1.45,1.54,1.17,2.15c-1.55-0.98-3.67-0.89-5.15-1.91c-0.81-0.55-1.6-0.97-2.48-1.42c-0.9-0.46-1.84-0.57-2.77-0.95 c-0.84-0.35-2.43-1.19-3.33-1.23c-0.42-0.02-0.78,0.22-0.88,0.62c-0.25,0.93,0.85,1.72,1.42,2.23c0.88,0.78,1.71,1.56,2.46,2.48 c1.59,1.93,3.62,3.59,4.88,5.77c0.53,0.92,0.81,1.91,1.61,2.66c0.61,0.58,1.9,1.37,2.15,0.07c0.44-2.31-2.03-3.73-3.33-5.2 c-0.69-0.78-1.34-1.6-2-2.4c-0.45-0.56-0.87-1.15-1.16-1.81c0.59-0.13,1.28,0.39,1.78,0.63c0.53,0.25,1.05,0.52,1.59,0.75 c1.2,0.52,2.43,0.96,3.66,1.4c1.18,0.42,2.31,0.97,3.46,1.48c0.45,0.2,0.96,0.63,1.45,0.67C145.69,46.41,146.53,45.88,146.16,45.26 z
        M146.39,39.73c-0.76,0.5-1.08,1.41-0.46,2.35c0.61,0.93,1.57,1,2.34,0.5c0.76-0.5,1.07-1.42,0.46-2.35 C148.11,39.3,147.15,39.23,146.39,39.73z M148.09,42.31c-0.68,0.45-1.42,0.27-1.88-0.42s-0.33-1.44,0.35-1.89 c0.68-0.45,1.43-0.27,1.88,0.42C148.9,41.11,148.77,41.86,148.09,42.31z M147.68,40.47c-0.2-0.3-0.48-0.31-0.82-0.09l-0.53,0.35 l0.99,1.5l0.26-0.17l-0.41-0.62l0.26-0.17l0.02-0.01l0.66,0.45l0.29-0.19l-0.72-0.47C147.81,40.87,147.82,40.68,147.68,40.47z M146.76,40.8l0.28-0.18c0.18-0.12,0.29-0.1,0.38,0.03c0.09,0.14,0.06,0.25-0.11,0.37l-0.28,0.18L146.76,40.8z"
        />
      </g>
    </g>
  </svg>
);
