import { forwardRef } from 'react';

import { useThemeIcon } from '../icon/useThemeIcon';

import { PlainIconStyles } from './plain-icon.styled';
import { PlainIconProps } from './types';

/**
 * A non-interactive icon for use inside other components, like the TextInput
 *
 * Use ActionIcon when you want to use an icon as a button
 */
const PlainIcon = forwardRef<SVGSVGElement, PlainIconProps>(function PlainIcon(
  { icon, title = '', 'data-testid': testId, 'aria-hidden': ariaHidden },
  forwardedRef,
) {
  const Icon = useThemeIcon(icon);
  if (!Icon) {
    return null;
  }

  return (
    <PlainIconStyles
      as={Icon}
      role="img"
      title={title}
      aria-hidden={ariaHidden}
      focusable="false"
      ref={forwardedRef}
      data-testid={testId}
    />
  );
});

export default PlainIcon;
