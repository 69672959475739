import { SVGIconComponent } from '../types';

export const qr: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path
      d="m3 11h8v-8h-8v8zm2-6h4v4h-4v-4zm-2 16h8v-8h-8v8zm2-6h4v4h-4v-4zm8-4v-8h8v8h-8zm2-2h4v-4h-4v4zm6 10h-2v-2h2v-2h-2v-2h-2v2h-2v-2h-2v2h2v2h-2v2h2v2h2v-2h2v2h2v-2zm-4-2h2v-2h-2v2zm0 0v2h-2v-2h2z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
);
