import { SVGIconComponent } from '../types';

export const invitationCode: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path
      fillRule="evenodd"
      d="M4.5 2C3.11928 2 2 3.11929 2 4.5V19.5C2 20.8807 3.11928 22 4.5 22H19.5C20.8807 22 22 20.8807 22 19.5V4.5C22 3.11929 20.8807 2 19.5 2H4.5ZM6.58333 14.5H7.83333V9.5H5.33333V10.75H6.58333V14.5ZM10.75 12.4167V13.25H13.25V14.5H9.5V12.4167C9.5 11.9583 9.87501 11.5833 10.3333 11.5833H12V10.75H9.5V9.5H12.4167C12.875 9.5 13.25 9.875 13.25 10.3333V11.5833C13.25 12.0417 12.875 12.4167 12.4167 12.4167H10.75ZM18.25 10.3333V13.6667C18.25 14.125 17.875 14.5 17.4167 14.5H14.5V13.25H17V12.4167H15.3333V11.5833H17V10.75H14.5V9.5H17.4167C17.875 9.5 18.25 9.875 18.25 10.3333Z"
      clipRule="evenodd"
    />
  </svg>
);
