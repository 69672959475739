import { SVGIconComponent } from '../types';

export const kioskCard: SVGIconComponent = ({ title = '', ...props }) => (
  <svg data-legacy-icon viewBox="0 0 84 80" fillRule="evenodd" clipRule="evenodd" {...props}>
    <title>{title}</title>
    <g transform="translate(-338.000000, -1111.000000)">
      <g transform="translate(338.000000, 1111.000000)">
        <path
          d="M49,44V33h28.5c3.6,0,6.5,3.1,6.5,6.9V44H49z M49,72V46h35v19.5c0,3.6-2.9,6.5-6.5,6.5
				H49z M75,56.4h-7.8c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3H75c0.7,0,1.3-0.6,1.3-1.3C76.2,57,75.7,56.4,75,56.4z M75,51.2
				H54.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3H75c0.7,0,1.3-0.6,1.3-1.3S75.7,51.2,75,51.2z"
        />
        <path
          d="M0,66.2V13.8V8.6C0,3.9,3.7,0,8.2,0h29.6C42.3,0,46,3.9,46,8.6v5.2v52.3v5.2c0,4.8-3.7,8.6-8.2,8.6H8.2
				C3.7,80,0,76.1,0,71.4V66.2z M23,74.8c1.8,0,3.3-1.5,3.3-3.5s-1.5-3.5-3.3-3.5s-3.3,1.5-3.3,3.5S21.2,74.8,23,74.8z M14.8,6.9
				c-0.9,0-1.6,0.8-1.6,1.7s0.7,1.7,1.6,1.7h16.4c0.9,0,1.6-0.8,1.6-1.7s-0.7-1.7-1.6-1.7H14.8z M23,38.7c-2.8,0-5.2-2.3-5.2-5.2
				c0-2.9,2.3-5.2,5.2-5.2c2.8,0,5.2,2.3,5.2,5.2c0,0.9,0.7,1.7,1.6,1.7c0.9,0,1.6-0.7,1.6-1.7c0-4.1-2.9-7.6-6.8-8.4V22
				c0-0.9-0.7-1.7-1.6-1.7s-1.6,0.7-1.6,1.7v3.1c-3.9,0.8-6.8,4.2-6.8,8.4c0,4.7,3.8,8.5,8.4,8.5c2.8,0,5.2,2.3,5.2,5.2
				c0,2.9-2.3,5.2-5.2,5.2c-2.8,0-5.2-2.3-5.2-5.2c0-0.9-0.7-1.7-1.6-1.7c-0.9,0-1.6,0.7-1.6,1.7c0,4.1,2.9,7.6,6.8,8.4v3.1
				c0,0.9,0.7,1.7,1.6,1.7s1.6-0.7,1.6-1.7v-3.1c3.9-0.8,6.8-4.2,6.8-8.4C31.4,42.5,27.7,38.7,23,38.7z"
        />
      </g>
    </g>
  </svg>
);
