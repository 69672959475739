import { SVGIconComponent } from '../types';

export const discover: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 32 20" {...props}>
    <title>{title}</title>
    <defs>
      <rect id="path-1" x="-3.55271368e-15" y="0" width="32" height="20" rx="2"></rect>
      <path
        d="M8.28458443,1.65286575 C8.28458443,2.57378152 8.95636181,3.26180411 9.85264965,3.26180411 C10.7268874,3.26180411 11.4169563,2.56468413 11.4169563,1.6708006 C11.4169563,0.782895367 10.7313976,0.085255538 9.85264965,0.085255538 C8.98668074,0.085255538 8.28458443,0.786794249 8.28458443,1.65286575 Z"
        id="path-3"
      ></path>
      <filter
        x="-16.0%"
        y="-15.7%"
        width="131.9%"
        height="131.5%"
        filterUnits="objectBoundingBox"
        id="filter-4"
      >
        <feGaussianBlur
          stdDeviation="0.5"
          in="SourceAlpha"
          result="shadowBlurInner1"
        ></feGaussianBlur>
        <feOffset dx="0" dy="0" in="shadowBlurInner1" result="shadowOffsetInner1"></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowInnerInner1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g id="icon-/-card-/-discovery" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="discover">
        <mask id="mask-discover" fill="white">
          <use xlinkHref="#path-1"></use>
        </mask>
        <use id="Mask" fill="#FFFFFF" xlinkHref="#path-1"></use>
        <g mask="url(#mask-discover)">
          <g transform="translate(6.000000, 7.000000)">
            <path
              d="M23.9991358,13 C25.1041826,13 25.9994727,12.1132936 25.9988117,11.0018986 L25.9952424,5 C23.8894584,6.25934099 15.9514946,10.6013121 3,13 L23.9991358,13 Z"
              id="pointed"
              fill="#F58220"
            ></path>
            <g id="logo" strokeWidth="1" fill="none" transform="translate(0.058669, 0.823930)">
              <g id="registered" transform="translate(18.291589, 0.140793)" fill="#231F20">
                <path
                  d="M0.768670649,0.443438941 C0.768670649,0.335057843 0.700314923,0.274787379 0.574081356,0.274787379 L0.407932036,0.274787379 L0.407932036,0.82250844 L0.530174028,0.82250844 L0.530174028,0.609447062 L0.674369684,0.82250844 L0.828045331,0.82250844 L0.655409701,0.596229855 C0.72825595,0.575611012 0.768670649,0.519570054 0.768670649,0.443438941 L0.768670649,0.443438941 Z M0.553125586,0.517455301 L0.530174028,0.517455301 L0.530174028,0.376295529 L0.554622426,0.376295529 C0.614995002,0.376295529 0.64592971,0.399029125 0.64592971,0.445553694 C0.64592971,0.49313564 0.613498161,0.517455301 0.553125586,0.517455301 L0.553125586,0.517455301 Z"
                  id="r"
                ></path>
                <path
                  d="M0.61771148,0.0848512096 C0.36773908,0.0848512096 0.168160318,0.297383899 0.168160318,0.562785418 C0.168160318,0.827129559 0.368736974,1.04019094 0.61771148,1.04019094 C0.862694411,1.04019094 1.06277212,0.825014806 1.06277212,0.562785418 C1.06277212,0.300556029 0.862694411,0.0848512096 0.61771148,0.0848512096 L0.61771148,0.0848512096 Z M0.617212533,0.954014747 C0.417633771,0.954014747 0.25647392,0.78060499 0.25647392,0.563842794 C0.25647392,0.344965845 0.416635877,0.171556088 0.617212533,0.171556088 C0.812300774,0.171556088 0.971963784,0.348137975 0.971963784,0.563842794 C0.971963784,0.778490237 0.812300774,0.954014747 0.617212533,0.954014747 L0.617212533,0.954014747 Z"
                  id="Fill-12"
                ></path>
              </g>
              <path
                d="M18.2893317,1.04282079 C18.2893317,0.470984862 17.9104703,0.142179205 17.2487157,0.142179205 L16.3972798,0.142179205 L16.3972798,3.19318379 L16.9710844,3.19318379 L16.9710844,1.96711558 L17.0462553,1.96711558 L17.839559,3.19318379 L18.5454139,3.19318379 L17.6185566,1.90785259 C18.051541,1.81635884 18.2893317,1.50990677 18.2893317,1.04282079 L18.2893317,1.04282079 Z M17.1379639,1.54629633 L16.9710844,1.54629633 L16.9710844,0.622001532 L17.1474855,0.622001532 C17.5050485,0.622001532 17.6994906,0.77821671 17.6994906,1.07479162 C17.6994906,1.38124369 17.5050485,1.54629633 17.1379639,1.54629633 L17.1379639,1.54629633 Z"
                id="r"
                fill="#231F20"
              ></path>
              <polyline
                id="e"
                fill="#231F20"
                points="14.3914693 3.19318379 16.018669 3.19318379 16.018669 2.67645206 14.9647728 2.67645206 14.9647728 1.85248847 15.9785778 1.85248847 15.9785778 1.33627659 14.9647728 1.33627659 14.9647728 0.658910942 16.018669 0.658910942 16.018669 0.142179205 14.3914693 0.142179205 14.3914693 3.19318379"
              ></polyline>
              <polyline
                id="v"
                fill="#231F20"
                points="12.7149074 2.19169115 11.9296219 0.142179205 11.3034482 0.142179205 12.5520371 3.27142135 12.8604884 3.27142135 14.1308768 0.142179205 13.5092134 0.142179205 12.7149074 2.19169115"
              ></polyline>
              <g id="Fill-1">
                <use fill="#F48027" fillRule="evenodd" xlinkHref="#path-3"></use>
                <use fill="black" fillOpacity="1" filter="url(#filter-4)" xlinkHref="#path-3"></use>
              </g>
              <path
                d="M5.79517439,1.66924105 C5.79517439,2.56676353 6.47471944,3.26180411 7.34770429,3.26180411 C7.59501659,3.26180411 7.80649743,3.2116385 8.06708992,3.08349527 L8.06708992,2.38351611 C7.83706693,2.62160779 7.63435604,2.71700042 7.37401411,2.71700042 C6.79645094,2.71700042 6.38601776,2.28240512 6.38601776,1.66456239 C6.38601776,1.0797302 6.80922999,0.617322874 7.34770429,0.617322874 C7.62082527,0.617322874 7.82829699,0.718433863 8.06708992,0.96042443 L8.06708992,0.260445272 C7.81576851,0.127883307 7.60779565,0.0735588941 7.36123506,0.0735588941 C6.49200875,0.0735588941 5.79517439,0.782895367 5.79517439,1.66924105"
                id="c"
                fill="#231F20"
              ></path>
              <path
                d="M4.76808917,1.31236345 C4.42355582,1.18032134 4.32257623,1.09298639 4.32257623,0.928453603 C4.32257623,0.736628643 4.50248528,0.590290631 4.75029872,0.590290631 C4.92218954,0.590290631 5.06326028,0.663589599 5.2136021,0.83669993 L5.5130329,0.430436497 C5.26597117,0.205601008 4.97080006,0.0914937481 4.64856742,0.0914937481 C4.12863528,0.0914937481 3.73148229,0.46656613 3.73148229,0.965103087 C3.73148229,1.38644219 3.9166533,1.60114059 4.45462646,1.80232287 C4.67963806,1.8844593 4.79414842,1.93930356 4.85152888,1.97621297 C4.9662898,2.05445053 5.02392084,2.1636192 5.02392084,2.29150251 C5.02392084,2.53921144 4.833989,2.72167908 4.57790676,2.72167908 C4.30428464,2.72167908 4.08403387,2.58053958 3.95173306,2.3148958 L3.58189218,2.6860693 C3.84599265,3.08817392 4.16346446,3.26674269 4.60045803,3.26674269 C5.19581165,3.26674269 5.61451363,2.85450097 5.61451363,2.26473019 C5.61451363,1.77996928 5.42107382,1.56007238 4.76808917,1.31236345"
                id="s"
                fill="#231F20"
              ></path>
              <polygon
                id="i"
                fill="#231F20"
                points="2.79209646 0.142179205 3.36539995 0.142179205 3.36539995 3.19318379 2.79209646 3.19318379"
              ></polygon>
              <path
                d="M0.948655131,0.142179205 L0.105738639,0.142179205 L0.105738639,3.19318379 L0.944646016,3.19318379 C1.38940724,3.19318379 1.71138932,3.08349527 1.99378138,2.8415047 C2.3285425,2.55246763 2.52724428,2.11787233 2.52724428,1.66924105 C2.52724428,0.768599469 1.87851931,0.142179205 0.948655131,0.142179205 L0.948655131,0.142179205 Z M1.61943024,2.43368171 C1.43801777,2.60341301 1.20473737,2.67645206 0.833894206,2.67645206 L0.679543267,2.67645206 L0.679543267,0.658910942 L0.833894206,0.658910942 C1.20473737,0.658910942 1.42899726,0.727531253 1.61943024,0.905580166 C1.81788144,1.08882759 1.93589977,1.3721463 1.93589977,1.66456239 C1.93589977,1.95801819 1.81788144,2.25147399 1.61943024,2.43368171 L1.61943024,2.43368171 Z"
                id="d"
                fill="#231F20"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
