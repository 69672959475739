import { forwardRef } from 'react';

import { useIdCounter } from '../../hooks';
import { PlainIcon } from '../plain-icon';
import { VisuallyHidden } from '../visually-hidden';

import {
  LeftIconContainer,
  RightIconContainer,
  StyledLocaleSelect,
  Wrapper,
} from './locale-select.styled';
import { LocaleSelectProps } from './types';

/**
 * Minimal UI to change locale settings
 */
const LocaleSelect = forwardRef<HTMLSelectElement, LocaleSelectProps>(
  (
    {
      id: propsId,
      options,
      value,
      onChange,
      reversed = false,
      'aria-label': ariaLabel,
      'data-testid': testId,
    },
    forwardedRef,
  ) => {
    const defaultId = useIdCounter('locale-select');
    const id = propsId || defaultId;

    return (
      <Wrapper htmlFor={id}>
        <VisuallyHidden>{ariaLabel}</VisuallyHidden>

        <StyledLocaleSelect
          id={id}
          data-testid={testId}
          ref={forwardedRef}
          value={value}
          onChange={onChange}
          $reversed={reversed}
        >
          {options.map(({ label, value: optionValue }) => (
            <option key={optionValue} value={optionValue}>
              {label}
            </option>
          ))}
        </StyledLocaleSelect>

        <LeftIconContainer $reversed={reversed}>
          <PlainIcon icon="language" aria-hidden />
        </LeftIconContainer>
        <RightIconContainer $reversed={reversed}>
          <PlainIcon icon="select" aria-hidden />
        </RightIconContainer>
      </Wrapper>
    );
  },
);

export default LocaleSelect;
