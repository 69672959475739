import { FC, useCallback, useEffect, useState } from 'react';

import { useIdCounter } from '../../hooks/use-id-counter';
import noop from '../../utils/noop';
import { roundByStep } from '../../utils/round-by-step';
import { MinusButton, PlusButton } from '../button2/button-internal';
import { VisuallyHidden } from '../visually-hidden';

import { checkIfPropsAreValid } from './check-if-props-are-valid';
import { DisplayedValue, Input, Wrapper } from './number-input.styled';
import { INumberInputProps } from './types';

/**
 * Quickly enable a user to enter a value.
 */
const NumberInput: FC<INumberInputProps> = ({
  // Required fields
  value,
  onChange,
  // Min/Max/Step/id
  min = 0,
  max = Infinity,
  step = 1,
  id: inputId,
  // Disabled props
  disabled,
  disableDecrement,
  disableIncrement,
  // Custom UI/UX props
  onStepButtonClick = noop,
  hideNumberInput,
  displayedValue,
  valueWidth,
  // Accessibility
  'aria-label': ariaLabel,
  'decrement-aria-label': decrementAriaLabel,
  'increment-aria-label': incrementAriaLabel,
  // Adding the possibility to override the native accessibility added to the component
  screenReaderTextOverride,
  // data-testids
  'data-testid': testId,
}) => {
  // Temporary input value when the user is typing the number input
  const [inputValue, setInputValue] = useState(value);

  // Use the fallback of useIdCounter if no id is passed as props
  const defaultInputId = useIdCounter('number-input-component');
  const uniqueId = inputId ?? defaultInputId;

  // Test ids for the increment and decrement buttons
  const dataTestId = testId ?? 'number-input';
  const decrementTestId = `decrement-button-for-${dataTestId}`;
  const incrementTestId = `increment-button-for-${dataTestId}`;

  // Send error messages when the input is not being used properly in dev
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      checkIfPropsAreValid({
        value,
        min,
        max,
        step,
        hideNumberInput,
        displayedValue,
      });
    }
  }, [displayedValue, hideNumberInput, max, min, step, value]);

  // Update the component if, for some reason, the value has been changed outside of the user's interaction
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // Handling step up and step down button click
  const handleChange = useCallback(
    async ({
      e,
      isIncrementing,
    }: {
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
      isIncrementing: boolean;
    }) => {
      e.preventDefault();
      onStepButtonClick();
      // Using the native stepUp and stepDown functions to handle incrementor / decrementor buttons clicks
      const element = document.getElementById(uniqueId) as HTMLInputElement;
      isIncrementing ? element.stepUp() : element.stepDown();
      onChange(Number(element.value));
    },
    [onChange, onStepButtonClick, uniqueId],
  );

  // Handle the number input change
  // This should only call the onChange function on input blur
  const handleInputChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  // Updating the input on blur and calling the onChange function if needed
  const handleInputBlur = useCallback(
    (e) => {
      // Rounding the inputValue by step
      const roundedValue = roundByStep(inputValue, step);

      // Making sure the new value is not out of boundaries
      let newValue = inputValue;
      if (disableDecrement && newValue < value) {
        // Dont allow decrementation if we disable the decrement
        setInputValue(value);
        newValue = value;
      } else if (disableIncrement && newValue > value) {
        // Dont allow incrementation if we disable the increment
        setInputValue(value);
        newValue = value;
      } else if (inputValue < min) {
        // Dont allow value lower than the min
        setInputValue(min);
        newValue = min;
      } else if (inputValue > max) {
        // Dont allow value higher than the min
        setInputValue(max);
        newValue = max;
      } else if (roundedValue !== inputValue) {
        // If the value are differents, update them
        setInputValue(roundedValue);
        newValue = roundedValue;
      }

      // Only call the onChange function if it's a new value
      // This prevents re-rendering input when the value is unchanged
      if (value !== newValue) {
        onChange(newValue);
      }

      // Updating the input value
      // This prevents showing negative zeros, leading zeros and "e" (ex: 0e1)
      if (roundedValue !== newValue || e.target.value !== newValue.toString()) {
        e.target.value = roundedValue.toString();
      }
    },
    [inputValue, min, max, onChange, step, value, disableDecrement, disableIncrement],
  );

  // Handle touching the enter keybord to update the input
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }, []);

  // When the user is clicking on the input, select all the input value
  // This alllow the use to not have to entre the backspace keys to change the value
  const handleInputClick = useCallback((e) => (e.target as HTMLInputElement).select(), []);

  // Disabled step up and down based on max/min
  const stepDownBtnDisabled = disabled || disableDecrement || value <= min;
  const stepUpBtnDisabled = disabled || disableIncrement || value >= max;

  return (
    <Wrapper $disabled={disabled}>
      <MinusButton
        onClick={(e) => handleChange({ e, isIncrementing: false })}
        disabled={stepDownBtnDisabled}
        data-testid={decrementTestId}
        aria-label={decrementAriaLabel}
      />

      <Input
        type="number"
        id={uniqueId}
        value={inputValue}
        data-testid={dataTestId}
        min={min}
        max={max}
        step={step}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onFocus={handleInputClick}
        onKeyDown={handleKeyDown}
        inputMode="decimal"
        disabled={disabled}
        aria-label={ariaLabel}
        $isHidden={hideNumberInput}
      />

      {hideNumberInput && (
        <DisplayedValue
          data-testid={`displayed-value-${dataTestId}`}
          $width={valueWidth}
          $disabled={disabled}
        >
          {displayedValue}
        </DisplayedValue>
      )}

      <PlusButton
        onClick={(e) => handleChange({ e, isIncrementing: true })}
        disabled={stepUpBtnDisabled}
        data-testid={incrementTestId}
        aria-label={incrementAriaLabel}
      />

      <VisuallyHidden aria-atomic aria-live="assertive">
        {screenReaderTextOverride ?? displayedValue ?? value}
      </VisuallyHidden>
    </Wrapper>
  );
};

export default NumberInput;
