import { FC, MouseEvent } from 'react';

import { Icon } from '../icon';

import {
  BottomHalfWrapper,
  BrandIconContainer,
  Container,
  Copyright,
  CopyrightAndLanguageSelectorContainer,
  LanguageSelectorWrapper,
  MiniIconsContainer,
  MiscLinksContainerLeft,
  MiscLinksContainerRight,
  NavLinksContainer,
  StyledHR,
  StyledMainLink,
  StyledMiscLink,
  TopHalfWrapper,
} from './footer.styled';
import { SocialMediaLinks } from './social-media-links';
import { IFooterProps, LinksProps } from './types';

const Links = ({ links, LinkComponent, onClick }: LinksProps) => {
  return (
    <>
      {links.map(({ locale, text, _key }) => {
        if (!locale || !text || !_key) {
          return null;
        }
        return (
          <LinkComponent
            href={locale}
            key={_key}
            onClick={(event: MouseEvent<HTMLAnchorElement>) => onClick(event, locale)}
          >
            {text}
          </LinkComponent>
        );
      })}
    </>
  );
};

/**
 * Footer Component
 */
const Footer: FC<IFooterProps> = ({
  id,
  copyrightText,
  onClick,
  mainLinks,
  miscellaneousLinks,
  localeSelector,
  socialMediaData,
}) => {
  if (!mainLinks || !miscellaneousLinks.length || !copyrightText) {
    return null;
  }

  const length = miscellaneousLinks.length;
  const half = Math.ceil(length / 2);
  const leftLinks = miscellaneousLinks.slice(0, half);
  const rightLinks = miscellaneousLinks.slice(half);

  return (
    <>
      <Container>
        <TopHalfWrapper id={id}>
          <BrandIconContainer>
            <Icon
              aria-hidden={true}
              icon={'logo'}
              color={'text-link-reversed'}
              height={'80px'}
              width={'80px'}
            />
          </BrandIconContainer>
          <NavLinksContainer>
            <Links links={mainLinks} LinkComponent={StyledMainLink} onClick={onClick} />
          </NavLinksContainer>
          <MiscLinksContainerLeft>
            <Links links={leftLinks} LinkComponent={StyledMiscLink} onClick={onClick} />
          </MiscLinksContainerLeft>
          <MiscLinksContainerRight>
            <Links links={rightLinks} LinkComponent={StyledMiscLink} onClick={onClick} />
          </MiscLinksContainerRight>
        </TopHalfWrapper>

        <StyledHR />
        <BottomHalfWrapper>
          <MiniIconsContainer>
            <SocialMediaLinks socialMediaData={socialMediaData} />
          </MiniIconsContainer>
          <CopyrightAndLanguageSelectorContainer>
            <Copyright>{copyrightText}</Copyright>
            {localeSelector && <LanguageSelectorWrapper>{localeSelector}</LanguageSelectorWrapper>}
          </CopyrightAndLanguageSelectorContainer>
        </BottomHalfWrapper>
      </Container>
    </>
  );
};

export default Footer;
