import { SVGIconComponent } from '../types';

export const WhatsNewIcon: SVGIconComponent = ({ title = "What's New", ...props }) => (
  <svg data-legacy-icon viewBox="0 0 67 67" {...props}>
    <title>{title}</title>
    <path
      d="M33.5 61.9l-7.34 4.706a2 2 0 0 1-2.856-.766L19.3 58.095l-8.71.405a2 2 0 0 1-2.09-2.09l.405-8.71-7.745-4.004a2 2 0 0 1-.766-2.856L5.1 33.5.394 26.16a2 2 0 0 1 .766-2.856L8.905 19.3 8.5 10.59a2 2 0 0 1 2.09-2.09l8.71.405 4.004-7.745A2 2 0 0 1 26.16.394L33.5 5.1 40.84.394a2 2 0 0 1 2.856.766L47.7 8.905l8.71-.405a2 2 0 0 1 2.09 2.09l-.405 8.71 7.745 4.004a2 2 0 0 1 .766 2.856L61.9 33.5l4.706 7.34a2 2 0 0 1-.766 2.856L58.095 47.7l.405 8.71a2 2 0 0 1-2.09 2.09l-8.71-.405-4.004 7.745a2 2 0 0 1-2.856.766L33.5 61.9zM21.442 28.33c-.086.02-.119.073-.099.16l1.54 6.666-4.782-5.94a.181.181 0 0 0-.196-.069l-2.465.57c-.086.02-.119.072-.1.158l3.406 14.748c.02.086.072.119.158.099l2.358-.544c.086-.02.119-.073.099-.159l-1.54-6.666 4.76 5.945c.032.038.09.093.197.068l2.486-.574c.086-.02.119-.073.1-.158l-3.406-14.749c-.02-.085-.072-.118-.158-.098l-2.358.544zm15.791 9.722c-.02-.086-.072-.119-.158-.1l-4.587 1.06-1.05-4.545 3.988-.92c.085-.02.118-.073.099-.158l-.505-2.187c-.02-.086-.073-.119-.159-.099l-3.987.92-.722-3.129 4.587-1.059c.086-.02.119-.073.1-.158l-.506-2.187c-.02-.086-.072-.119-.158-.099l-7.974 1.841c-.086.02-.119.073-.1.159l3.406 14.748c.02.085.072.118.158.099l7.974-1.841c.086-.02.119-.073.1-.159l-.506-2.186zm4.402-14.383c-.065.015-.081.041-.083.132l.262 7.097-2.98-6.447c-.04-.08-.072-.119-.179-.094l-3.28.757c-.15.035-.13.12-.11.206l6.642 14.001c.036.06.062.076.127.061l2.229-.515c.064-.015.08-.04.087-.11l-.119-6.972 2.949 6.319c.036.059.063.075.127.06l2.23-.514c.064-.015.08-.041.087-.11l-.168-15.496c-.02-.086-.04-.171-.19-.137l-2.358.545c-.064.014-.102.046-.099.158l.278 7.07-2.984-6.468c-.041-.08-.067-.097-.153-.077l-2.315.534z"
      fillRule="evenodd"
    />
  </svg>
);
